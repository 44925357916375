<template>
  <a-card :bordered="false">
    <a-descriptions title="医院基本信息" :column="2" >
      <a-descriptions-item label="医院名称">{{ this.info.name }}</a-descriptions-item>
      <a-descriptions-item label="医院级别">{{ this.info.level | levelFilter }}</a-descriptions-item>
      <a-descriptions-item label="城市">{{ this.info.regionName }}</a-descriptions-item>
      <a-descriptions-item label="状态">{{ this.info.status| statusFilter }}</a-descriptions-item>
      <a-descriptions-item label="Tips">{{ this.info.tips }}</a-descriptions-item>
      <a-descriptions-item label="备注">{{ this.info.remarks }}</a-descriptions-item>
    </a-descriptions>
    <a-descriptions title="医院地址" :column="2" >
      <a-descriptions-item v-for="(item,index) in this.addressList" :label="'地址'+(index+1)">{{ item.address }}</a-descriptions-item>
    </a-descriptions>
    <a-divider style="margin-bottom: 32px"/>
    <div class="title">科室信息</div>
    <s-table
      ref="table"
      :columns="columns"
      :data="loadData"
      :scroll="{ x: 800,y: 600 }"
      bordered
      rowKey="key"
      size="default"
    >
    </s-table>
  </a-card>
</template>

<script>
import { STable } from '@/components'
import { getById } from '@/api/nosodochium'
import { FetchList } from '@/api/nosodochiumdep'
export default {
  components: {
    STable
  },
  data () {
    return {
      nosoId: 0,
      info: {},
      addressList: [],
      queryParam: {},
      columns: [
        {
          title: '排序',
          dataIndex: 'listorder',
          sorter: true
        },
        {
          title: '对外科室',
          dataIndex: 'name'
        },
        {
          title: '对内一级科室',
          dataIndex: 'depName'
        },
        {
          title: '对内二级科室',
          dataIndex: 'sectionName'
        },
        {
          title: '状态',
          dataIndex: 'status',
          sorter: true,
          customRender: (val) => {
            return val === 1 ? '启用' : '禁用'
          }
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        var searchParam = []

        searchParam = this.getCommonOperate(searchParam, ['=__status', 'like__name', '=__nosoId'])
        const parms = Object.assign(parameter, searchParam)
        if (parms['=__nosoId'] > 0) {
          return FetchList(parms)
            .then(res => {
              return res.result
            })
        } else {
          return []
        }
      }
    }
  },
  filters: {
    statusFilter (status) {
      const statusMap = {
        1: '启用',
        2: '禁用'

      }
      return statusMap[status]
    },
    levelFilter (level) {
      const statusMap = {
        1: '一级甲等',
        2: '一级乙等',
        3: '一级丙等',
        4: '二级甲等',
        5: '二级乙等',
        6: '二级丙等',
        7: '三级甲等',
        8: '三级乙等',
        9: '三级丙等',
        10: '三甲'
      }
      return statusMap[level]
    }
  },
  methods: {
    getCommonOperate (searchParam, fileds) {
      for (var key in this.queryParam) {
        var value = this.queryParam[key]
        if (typeof value !== 'number') {
          if (value === undefined || value === '' || value === null) {
            continue
          }
          value = value.toString().trim()
          if (value.length === 0) continue
        } else {
          if (value === 0) {
            continue
          }
        }
        for (var j = 0; j < fileds.length; j++) {
          var array = fileds[j].split('__')
          if (array[1] === key) {
            searchParam[fileds[j]] = value
          }
        }
      }
      return searchParam
    },
    setDetailValue (value) {
      this.nosoId = value
      this.getInfo(value)
      this.queryParam.nosoId = value
      this.$refs.table.refresh(true)
    },
    getInfo (value) {
      getById({ id: value }).then(res => {
        this.addressList = res.result.address
        this.info = res.result.info
      })
    }
  }
}
</script>
<style lang="less" scoped>
.title {
  color: rgba(0,0,0,.85);
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 16px;
}
</style>
