<template>
  <a-card :bordered="false">
    <page-header-wrapper :Style="{ padding: '3px 10px'}" :title="false"></page-header-wrapper>
    <a-form layout="inline">
      <a-form-item label="医院名称">
        <a-input allowClear v-model="queryParam.name" placeholder="请输入医院名称"/>
      </a-form-item>
      <a-form-item label="地区">
        <a-input allowClear v-model="queryParam.regionName" placeholder="请输入地区"/>
      </a-form-item>
      <a-form-item label="状态">
        <a-select allowClear v-model="queryParam.status" style="min-width:100px" placeholder="请选择">
          <a-select-option :value="1">启用</a-select-option>
          <a-select-option :value="2">禁用</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item
        label="标签"
      >
        <a-select
          v-model="queryParam.tagsId"
          style="min-width:100px"
          placeholder="请选择"
          allowClear
        >
          <a-select-option v-for="item in tagsData" :key="item.tagsId" :value="item.tagsId">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item>
        <a-button type="primary" @click="$refs.table.refresh(true);onClearSelected()">查询</a-button>
      </a-form-item>
      <a-form-item>
        <a-button style="margin-left: 8px" @click="clearSearch()">重置</a-button>
      </a-form-item>
      <a-form-item>
        <a-button style="margin-left: 8px" @click="download" :loading="nosodocLoading">下载</a-button>
      </a-form-item>
    </a-form>
    <div class="table-operator" align="right">
      <a-button v-action:add icon="plus" type="primary" @click="handleAdd">新建</a-button>
      <a-button icon="arrow-up" type="primary" @click="importExcel">导入</a-button>
      <a-button icon="arrow-down" type="primary" @click="handleStockShow">批量库存</a-button>
      <a-dropdown>
        <a-menu slot="overlay">
          <a-menu-item key="1" @click="downHospitalExcel">
            医院模板
          </a-menu-item>
          <a-menu-item key="2" @click="downDepartmentExcel">
            科室模板
          </a-menu-item>
        </a-menu>
        <a-button>
          模板下载
          <a-icon type="down"/>
        </a-button>
      </a-dropdown>
      <a-dropdown v-if="selectedRowKeys.length > 0" v-action:del>
        <a-menu slot="overlay">

          <a-menu-item key="1" @click="moreDel">
            批量删除
          </a-menu-item>
          <a-menu-item key="2" @click="moreClearstock">
            清空库存
          </a-menu-item>
          <a-menu-item key="3" @click="changeStatus(2)">
            批量禁止
          </a-menu-item>
          <a-menu-item key="4" @click="changeStatus(1)">
            批量启用
          </a-menu-item>
        </a-menu>
        <a-button>
          批量操作
          <a-icon type="down"/>
        </a-button>
      </a-dropdown>
    </div>
    <div class="ant-alert ant-alert-info" style="margin-bottom: 16px;">
      <i class="anticon anticon-info-circle ant-alert-icon"></i> 已选择&nbsp;<a style="font-weight: 600">{{
        selectedRowKeys.length
      }}</a>项&nbsp;&nbsp;
      <a style="margin-left: 24px" @click="onClearSelected">清空</a>
    </div>

    <s-table
      ref="table"
      :columns="columns"
      :data="loadData"
      :rowSelection="rowSelection"
      :scroll="{ x: 800,y: 600 }"
      :pagination="{
        pageSizeOptions:['10', '20', '50','100', '200' ,'300']
      }"
      :pageSize="100"
      bordered
      rowKey="key"
      size="default"
    >
      <span slot="action" slot-scope="text, record">
        <a @click="handleDetail(record)">详情</a>
        <a-divider v-action:state type="vertical"/>
        <a @click="handleTable(record)">科室</a>
        <a-divider v-action:state type="vertical"/>
        <a @click="handleSetStock(record)">库存</a>
        <a-divider v-action:state type="vertical"/>
        <a-dropdown>
          <a class="ant-dropdown-link">
            更多 <a-icon type="down"/>
          </a>
          <a-menu slot="overlay">
            <a-menu-item>
              <a v-action:edit @click="handleEdit(record)">编辑</a>
            </a-menu-item>
            <a-menu-item>
              <a v-if="record.status==1" @click="handleStatus(record)">禁用</a>
              <a v-if="record.status==2" @click="handleStatus(record)">启用</a>
            </a-menu-item>
            <a-menu-item>
              <a @click="handleDel(record)">删除</a>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </span>
    </s-table>
    <a-modal
      v-model="visible"
      :confirmLoading="loading"
      :title="title"
      :width="900"
      @cancel="handleCancel"
      @ok="handleOk"
    >
      <formCreate
        ref="createModal"
        :model="mdl"
        @submitForm="submitForm"
      />

    </a-modal>

    <a-modal
      v-model="stockvisible"
      title="库存管理"
      :width="900"
    >
      <CalendarPage ref="calendar"></CalendarPage>
    </a-modal>
    <a-modal
      v-model="modalShow3"
      title="导入Excel"
      :width="900"
      @cancel="handleCanceld"
      @ok="handleExcelImport"
    >
      <a-select allowClear v-model="uploattype" style="min-width:100px" placeholder="请选择">
        <a-select-option :value="1">导入医院</a-select-option>
        <a-select-option :value="2">导入科室</a-select-option>
      </a-select>

      <a-upload accept=".xls" :file-list="fileList" :before-upload="beforeUpload">
        <a-button>
          <a-icon type="upload"/>
          请选择上传文件
        </a-button>
      </a-upload>
      <a-button
        type="primary"
        :disabled="fileList.length === 0"
        :loading="uploading"
        style="margin-top: 16px"
        @click="handleUpload"
      >
        {{ uploading ? '正在上传' : '开始上传' }}
      </a-button>

      <a-table
        v-if="uploattype==1"
        :columns="DataColumns"
        :data-source="Datayiyuan"
        :scroll="{ x: 1300,y: 600 }"
      >
        <template slot="name" slot-scope="name"> {{ name.first }} {{ name.last }}</template>
      </a-table>

      <a-table
        v-if="uploattype==2"
        :columns="DataDepColumns"
        :data-source="Dataksuan"
      >
        <template slot="name" slot-scope="name"> {{ name.first }} {{ name.last }}</template>
      </a-table>
    </a-modal>

    <a-modal
      v-model="modalStockShow"
      title="设置库存"
      :width="900"
      @cancel="handleStockCancel"
      @ok="handleStockOk"
    >
      <a-form-model
        ref="ruleStockForm"
        :model="modelForm"
        :label-col="{ span: 4 }"
        :wrapper-col=" { span: 20 }"
      >
        <a-form-model-item label="选择类型" prop="type">
          <a-radio-group v-model="modelForm.type">
            <a-radio :value="1">
              库存时间段
            </a-radio>
            <a-radio :value="2">
              指定日期
            </a-radio>
          </a-radio-group>
        </a-form-model-item>
        <div v-if="modelForm.type==1">
          <a-form-model-item label="日期" prop="stockdate">
            <a-range-picker
              v-model="modelForm.stockdate"
              :disabled-date="disabledDate"
              format="YYYY-MM-DD"
            />
          </a-form-model-item>
          <a-form-model-item label="库存" prop="stock">
            <a-input-number v-model="modelForm.stock" :min="0"/>
          </a-form-model-item>
        </div>
        <div v-else>
          <a-form-model-item
            v-for="(item, index) in modelForm.stocklist"
            :key="index"
          >

            <a-date-picker :disabled-date="disabledDate" v-model="modelForm.stocklist[index].datevalue"/>
            <a-input-number
              placeholder="请输入库存"
              v-model="modelForm.stocklist[index].stockvalue"
              style="margin-left: 10px;width: 140px"
              :min="0"
            />
            <a-icon
              v-if="modelForm.stocklist.length>1"
              :disabled="modelForm.stocklist.length === 1"
              class="dynamic-delete-button"
              type="minus-circle-o"
              style="margin-left: 10px;"
              @click="removeDomain(index)"
            />
            <a-button v-if="index==0" type="primary" style=" margin-left: 8px" @click="addDomain()">
              <a-icon type="plus"/>
              增加
            </a-button>
          </a-form-model-item>
        </div>
      </a-form-model>

    </a-modal>
    <a-modal
      v-model="listVisible"
      :confirmLoading="listLoading"
      title="科室管理"
      :width="900"
    >
      <list-table ref="listTable"></list-table>
      <template slot="footer">
        <a-button key="back" @click="() => listVisible=false">
          取消
        </a-button>
      </template>
    </a-modal>

    <a-modal
      v-model="detailVisible"
      title="详情"
      :width="1000"
    >
      <detail ref="detail"></detail>
      <template slot="footer">
        <a-button key="back" @click="() => detailVisible=false">
          取消
        </a-button>
      </template>
    </a-modal>
  </a-card>
</template>

<script>
import tree from 'vue-giant-tree'
import { STable } from '@/components'
import {
  clearstock,
  create,
  del,
  download,
  DownloadExcel,
  excelShow,
  FetchList,
  getById,
  importSave,
  moreDel, moreStatus,
  update
} from '@/api/nosodochium'
import formCreate from './from'
import CalendarPage from './calendarlist'
import storage from 'store'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import moment from 'moment'
import { BatchStockSave } from '@/api/nosodochiumstock'
import listTable from './list/index'
import detail from './detail'
import { DepartmentDownload, excelShow as excelDepShow, importSave as importDepSave } from '@/api/nosodochiumdep'
import { formatterTime } from '@/utils/util'
import { allList as TagsAllList } from '@/api/tags'

export default {
  components: {
    STable,
    tree,
    CalendarPage,
    formCreate,
    listTable,
    detail
  },
  data () {
    return {
      nosodocLoading: false,
      uploattype: 1,
      detailVisible: false,
      detailLoading: false,
      impres: false,
      modalShow3: false,
      check: {
        enable: true
      },
      progresshide: true,
      Dataksuan: [],
      tagsData: [],
      Datayiyuan: [],
      DatayiyuanErr: [],
      DataksuanErr: [],
      modalStockShow: false,
      editstatus: true,
      selectedRowKeys: [],
      selectedRows: [],
      visible: false,
      stockvisible: false,
      confirmLoading: false,
      loading: false,
      title: '',
      importcount: 0,
      mdl: null,
      // 查询参数
      queryParam: {},
      orgTree: [],
      // 表头
      columns: [
        {
          title: 'ID',
          dataIndex: 'id',
          sorter: true
        },
        {
          title: '医院名称',
          dataIndex: 'name'
        },
        {
          title: '地区名称',
          dataIndex: 'regionName'
        },
        {
          title: '库存',
          dataIndex: 'sotoksum'
        },

        {
          title: '状态',
          dataIndex: 'status',
          sorter: true,
          customRender: (val) => {
            return val === 1 ? '启用' : '禁用'
          }
        },
        {
          title: '更新时间',
          align: 'center',
          dataIndex: 'updateTime',
          sorter: true,
          customRender: (val) => {
            return formatterTime(val)
          }
        },
        {
          title: '操作',
          width: '220px',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' }
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        var searchParam = []
        searchParam = this.getCommonOperate(searchParam, ['tagsId', '=__status', 'like__name', 'like__regionName'])
        return FetchList(Object.assign(parameter, searchParam))
          .then(res => {
            return res.result
          })
      },
      headers: {
        'Access-Token': storage.get(ACCESS_TOKEN),
        'contextType': 'multipart/form-data; charset=utf-8'
      },
      fileList: [],

      uploading: false,
      modelForm: {
        type: 1,
        stock: 0,
        stocklist: []
      },
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      listVisible: false,
      listLoading: false,
      DataColumns: [
        {
          title: '医院名称',
          dataIndex: 'name'
        },
        {
          title: '城市',
          dataIndex: 'regionName'
        },
        {
          title: '医院级别',
          dataIndex: 'levelName'
        },
        {
          title: '医院地址1',
          dataIndex: 'address1'
        },
        {
          title: '医院地址2',
          dataIndex: 'address2'
        },
        {
          title: '医院地址3',
          dataIndex: 'address3'
        },
        {
          title: 'Tips',
          dataIndex: 'tips'
        },
        {
          title: '标签',
          dataIndex: 'tagName'
        },
        {
          title: '备注',
          dataIndex: 'remarks'
        }
      ],
      DataDepColumns: [
        {
          title: '医院名称',
          dataIndex: 'name'
        },
        {
          title: '外部科室',
          dataIndex: 'depname'
        },
        {
          title: '内部科室一级',
          dataIndex: 'firstname'
        },
        {
          title: '内部科室二级',
          dataIndex: 'secname'
        }
      ]
    }
  },
  computed: {
    rowSelection () {
      return {
        selectedRowKeys: this.selectedRowKeys,
        onChange: this.onSelectChange
      }
    }
  },
  created () {
    this.getTagsAllList()
  },
  methods: {
    getTagsAllList () {
      TagsAllList().then(res => {
        this.tagsData = res.result
      })
    },
    disabledDate (current) {
      const weekDay = current.format('d')
      return current && current <= moment().add(0, 'days')
    },
    getCommonOperate (searchParam, fileds) {
      for (var key in this.queryParam) {
        var value = this.queryParam[key]
        if (typeof value !== 'number') {
          if (value === undefined || value === '' || value === null) {
            continue
          }
          value = value.toString().trim()
          if (value.length === 0) continue
        } else {
          if (value === 0) {
            continue
          }
        }
        for (var j = 0; j < fileds.length; j++) {
          if (fileds[j] === key) {
            searchParam[fileds[j]] = value
          } else {
            var array = fileds[j].split('__')
            if (array[1] === key) {
              searchParam[fileds[j]] = value
            }
          }
        }
      }
      return searchParam
    },

    handleAdd () {
      this.title = '新增'
      this.mdl = null
      this.visible = true
      this.editstatus = false
      this.$nextTick(function () {
        this.$refs.createModal.reset()
      })
    },
    handleEdit (record) {
      this.title = '编辑'
      this.visible = true
      this.editstatus = true
      this.$nextTick(function () {
        getById({ id: record.id }).then(res => {
          this.$refs.createModal.editReset(res.result)
        })
      })
    },
    handleOk () {
      this.$refs.createModal.onSubmit()
    },
    submitForm (params) {
      const self = this
      const values = {}
      Object.assign(values, params)
      if (values.regionIds.length > 1) {
        values.regionId = values.regionIds[values.regionIds.length - 1]
      }
      values.regionIds = JSON.stringify(values.regionIds)
      values.tagsIds = JSON.stringify(values.tagsIds)
      if (values.id > 0) {
        update(values)
          .then(res => {
            if (res.code === 1) {
              this.visible = false
              this.confirmLoading = false
              // 刷新表格
              self.$refs.table.refresh()
              self.$message.info('修改成功')
            } else {
              self.$message.error(res.message)
            }
          })
      } else {
        create(values)
          .then(res => {
            if (res.code === 1) {
              this.visible = false
              this.confirmLoading = false
              // 刷新表格
              self.$refs.table.refresh()
              self.$message.info('新增成功')
            } else {
              self.$message.error(res.message)
            }
          })
      }
    },
    handleCancel () {
      this.visible = false
    },
    handleDel (record) {
      const self = this
      this.$confirm({
        title: '确定要删除吗？',
        content: '删除后将不可恢复，请谨慎操作！',
        okText: '确认',
        cancelText: '取消',
        onOk () {
          del(record)
            .then(res => {
              if (res.code === 1) {
                // self.$refs.table.refresh(true)
                self.$refs.table.refresh(true)
                self.$message.info(res.message)
              } else {
                self.$refs.table.refresh(true)
                self.$message.error(res.message)
              }
            })
        }

      })
    },
    handleStatus (record) {
      const self = this
      this.$nextTick(function () {
        if (record.status === 1) {
          record.status = 2
        } else {
          record.status = 1
        }
        update(record)
          .then(res => {
            // 刷新表格
            self.$refs.table.refresh()
            self.$message.info('修改成功')
          })
      })
    },
    moreDel () {
      const self = this

      const arr = []
      if (this.selectedRows.length > 0) {
        for (let i = 0; i < this.selectedRows.length; i++) {
          arr.push(this.selectedRows[i].id)
        }
        this.$confirm({
          title: '确定要删除吗？',
          content: '删除后将不可恢复，请谨慎操作！',
          okText: '确认',
          cancelText: '取消',
          onOk () {
            moreDel({ 'ids': arr.join(',') })
              .then(res => {
                if (res.code === 1) {
                  self.$refs.table.refresh()
                  this.selectedRowKeys = []
                  this.selectedRows = []
                  self.$message.info(res.message)
                } else {
                  self.$message.error(res.message)
                }
              })
          }
        })
      }
    },

    moreClearstock () {
      const self = this
      const arr = []
      if (this.selectedRows.length > 0) {
        for (let i = 0; i < this.selectedRows.length; i++) {
          arr.push(this.selectedRows[i].id)
        }
        this.$confirm({
          title: '确定要清除库存吗？',
          content: '清除库存后将不可恢复，请谨慎操作！',
          okText: '确认',
          cancelText: '取消',
          onOk () {
            clearstock({ 'ids': arr.join(',') })
              .then(res => {
                if (res.code === 1) {
                  self.$refs.table.refresh()
                  this.selectedRowKeys = []
                  this.selectedRows = []
                  self.$message.info(res.message)
                } else {
                  self.$message.error(res.message)
                }
              })
          }
        })
      }
    },

    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    onClearSelected () {
      this.selectedRowKeys = []
      this.selectedRows = []
    },
    handleSetStock (record) {
      this.stockvisible = true
      this.$nextTick(function () {
        this.$refs.calendar.setNoseId(record.id)
      })
    },
    downHospitalExcel () {
      download({}).then(res => {
        const fileName = '医院导入模板.xls'
        const content = res
        const blob = new Blob([content], { type: 'application/vnd.ms-excel;charset=utf-8' })
        if ('download' in document.createElement('a')) {
          // 非IE下载
          const elink = document.createElement('a')
          elink.download = fileName
          elink.style.display = 'none'
          elink.href = URL.createObjectURL(blob)
          document.body.appendChild(elink)
          elink.click()
          URL.revokeObjectURL(elink.href) // 释放URL 对象
          document.body.removeChild(elink)
        } else {
          // IE10+下载
          navigator.msSaveBlob(blob, fileName)
        }
      })
    },
    downDepartmentExcel () {
      DepartmentDownload({}).then(res => {
        const fileName = '科室导入模板.xls'
        const content = res
        const blob = new Blob([content], { type: 'application/vnd.ms-excel;charset=utf-8' })
        if ('download' in document.createElement('a')) {
          // 非IE下载
          const elink = document.createElement('a')
          elink.download = fileName
          elink.style.display = 'none'
          elink.href = URL.createObjectURL(blob)
          document.body.appendChild(elink)
          elink.click()
          URL.revokeObjectURL(elink.href) // 释放URL 对象
          document.body.removeChild(elink)
        } else {
          // IE10+下载
          navigator.msSaveBlob(blob, fileName)
        }
      })
    },
    importExcel () {
      this.modalShow3 = true
      this.impfile = null
      this.imperror = null
      this.impres = false
    },
    handleChange (info) {
      this.impres = false
      this.imperror = null
    },
    beforeUpload (file) {
      this.fileList = file
      return false
    },
    handleUpload () {
      const self = this
      const formData = new FormData()
      formData.append('file', this.fileList)
      this.uploading = true

      if (this.uploattype === 1) {
        excelShow(formData)
          .then(res => {
            if (res.code === 1) {
              this.Datayiyuan = res.result
              this.uploading = false
              this.fileList = []
            } else {
              self.$message.error('解析失败')
              this.uploading = false
            }
          })
      } else {
        excelDepShow(formData)
          .then(res => {
            if (res.code === 1) {
              this.Dataksuan = res.result
              this.uploading = false
              this.fileList = []
            } else {
              self.$message.error('解析失败')
              this.uploading = false
            }
          })
      }
    },
    handleCanceld () {
      this.modalShow3 = false
      this.impfile = null
      this.imperror = null
      this.impres = false
    },
    handleStockShow () {
      this.modalStockShow = true
      this.modelForm.stocklist = []
      if (this.modelForm.stocklist.length < 1) {
        this.addDomain()
      }
    },
    addDomain () {
      this.modelForm.stocklist.push({ datevalue: undefined, stockvalue: 0 })
    },
    removeDomain (index) {
      if (index !== -1) {
        this.modelForm.stocklist.splice(index, 1)
      }
    },
    handleStockCancel () {
      this.modalStockShow = false
    },
    handleStockOk () {
      const self = this
      let param = {}
      if (this.selectedRows.length <= 0) {
        this.$message.info('请选择医院')
      }
      const params = {}
      Object.assign(params, this.modelForm)

      if (params.type === 1) {
        if (params.stock < 0) {
          this.$message.info('请输入库存')
          return false
        }
        if (params.stockdate.length !== 2) {
          this.$message.info('请选择日期')
          return false
        }
        param = {
          stock: params.stock,
          startdate: moment(params.stockdate[0]).format('YYYY-MM-DD'),
          enddate: moment(params.stockdate[1]).format('YYYY-MM-DD'),
          type: params.type
        }
      } else {
        param = {
          type: params.type
        }

        const arr = []
        const item = params.stocklist
        for (let i = 0; i < item.length; i++) {
          if (item[i].stockvalue >= 0) {
            arr.push({ stockdate: moment(item[i].datevalue).format('YYYY-MM-DD'), stockvalue: item[i].stockvalue })
          }
        }
        param.stockList = JSON.stringify(arr)
      }
      console.log(param)
      this.importSava(param, 0)
    },

    importSava (param, index) {
      const dataparams = {}
      if (index >= this.selectedRows.length) {
        this.$message.error('设置完成')
        this.modalStockShow = false
        return ''
      }
      Object.assign(dataparams, param)
      dataparams.noseId = this.selectedRows[index].id
      BatchStockSave(dataparams)
        .then(res => {
          this.importSava(param, (index + 1))
        })
    },
    handleTable (record) {
      this.listVisible = true

      this.$nextTick(function () {
        this.$refs.listTable.setParem(record.id)
      })
    },
    handleDetail (record) {
      this.detailVisible = true
      this.$nextTick(function () {
        this.$refs.detail.setDetailValue(record.id)
      })
    },
    download () {
      var searchParam = []
      searchParam = this.getCommonOperate(searchParam, ['tagsId', '=__status', 'like__name', 'like__regionName'])
      const parameter = {}
      this.nosodocLoading = true
      DownloadExcel(Object.assign(parameter, searchParam)).then(res => {
        this.nosodocLoading = false
        const fileName = '医院下载.xls'
        const content = res
        const blob = new Blob([content], { type: 'application/vnd.ms-excel;charset=utf-8' })
        if ('download' in document.createElement('a')) {
          // 非IE下载
          const elink = document.createElement('a')
          elink.download = fileName
          elink.style.display = 'none'
          elink.href = URL.createObjectURL(blob)
          document.body.appendChild(elink)
          elink.click()
          URL.revokeObjectURL(elink.href) // 释放URL 对象
          document.body.removeChild(elink)
        } else {
          // IE10+下载
          navigator.msSaveBlob(blob, fileName)
        }
      })
    },
    handleExcelImport () {
      this.impfile = null
      this.imperror = null
      this.impres = false

      // 导入医院
      if (this.uploattype === 1) {
        if (this.Datayiyuan.length > 0) {
          this.progresshide = false
          this.importcount = this.Datayiyuan.length
          this.importYiyuanSave()
        } else {
          this.$message.error('暂无数据导入')
        }

        // 到
      } else if (this.uploattype === 2) {
        if (this.Dataksuan.length > 0) {
          this.importcount = this.Dataksuan.length
          this.progresshide = false
          this.importDepDdataSave()
        } else {
          this.$message.error('暂无数据导入')
        }
        // if (this.Dataksuan.length > 0) {
        //   this.progresshide = false
        //   const count = this.Dataksuan.length
        //   // 导入科室
        //   const arr = []
        //   for (let i = 0; i < count; i++) {
        //     importDepSave(this.Dataksuan[i])
        //       .then(res => {
        //         this.percent = Math.ceil((i + 1) / count) * 100
        //         if (res.code === 1) {
        //           this.Dataksuan = res.result
        //           this.uploading = false
        //           this.fileList = []
        //         } else {
        //           arr.push(this.Dataksuan[i])
        //           this.uploading = false
        //         }
        //         if (i === count) {
        //           if (arr.length > 0) {
        //             this.$message.error('导入失败' + arr.length + '条')
        //             this.Dataksuan = arr
        //           } else {
        //             this.Dataksuan = []
        //             this.$message.success('导入成功' + count + '条')
        //           }
        //         }
        //       })
        //   }
      } else {
        this.$message.error('暂无数据导入')
      }
    },
    importYiyuanSave (count) {
      if (this.Datayiyuan.length === 0) {
        const err = this.DatayiyuanErr.length
        this.$message.success('成功导入' + this.importcount - err + '条', '失败' + err + '条')
        return
      }
      importSave(this.Datayiyuan[0])
        .then(res => {
          if (res.code === 1) {
            // 表示成功的
            this.uploading = false
            this.fileList = []
          } else {
            this.DatayiyuanErr.push(this.Datayiyuan[0])
          }
          this.Datayiyuan.splice(0, 1)
          this.importYiyuanSave()
        })
    },
    importDepDdataSave () {
      if (this.Dataksuan.length === 0) {
        const err = this.DataksuanErr.length
        this.$message.success('成功导入' + this.importcount - err + '条', '失败' + err + '条')
        return
      }
      importDepSave(this.Dataksuan[0])
        .then(res => {
          if (res.code === 1) {
            // 表示成功的
            this.uploading = false
            this.fileList = []
          } else {
            this.DataksuanErr.push(this.Dataksuan[0])
          }
          this.Dataksuan.splice(0, 1)
          this.importYiyuanSave()
        })
    },
    changeStatus (value) {
      const self = this

      const arr = []
      if (this.selectedRows.length > 0) {
        for (let i = 0; i < this.selectedRows.length; i++) {
          arr.push(this.selectedRows[i].id)
        }
        this.$confirm({
          title: '确定要批量禁止吗？',
          content: '请谨慎操作！',
          okText: '确认',
          cancelText: '取消',
          onOk () {
            moreStatus({ 'ids': arr.join(','), status: value })
              .then(res => {
                if (res.code === 1) {
                  self.$refs.table.refresh()
                  this.selectedRowKeys = []
                  this.selectedRows = []
                  self.$message.info(res.message)
                } else {
                  self.$message.error(res.message)
                }
              })
          }
        })
      }
    },
    clearSearch () {
      this.queryParam = {}
      this.onClearSelected()
    }
  }
}
</script>
