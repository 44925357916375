<template>
  <div>
    <a-form
      :form="form"
      :labelCol="labelCol"
      :wrapperCol="wrapperCol">
      <a-form-item
        hidden
        label="nosoDepId"
      >
        <a-input
          v-decorator="['nosoDepId',{ initialValue:'0' }]"
          name="nosoDepId"></a-input>
      </a-form-item>

      <a-form-item
        label="对外科室名称"
      >
        <a-input
          v-decorator="[
            'name',
            {rules: [{ required: true, message: '请输入对外科室名称' }]}
          ]"
          name="name"
          placeholder="请输入对外科室名称"/>
      </a-form-item>

      <a-form-item
        label="内部一级科室"
        prop="productId"
      >
        <a-select
          allowClear
          v-decorator="[
            'depId',
            {rules: [{ required: true, message: '请选择内部一级科室' }]}
          ]"
          placeholder="内部一级科室"
          name="depId"
          @change="onChange"
        >
          <a-select-option v-for="item in depdata" :key="item.depId" :value="item.depId">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item
        label="二级科室"
        prop="sectionId"
      >
        <a-select
          allowClear
          v-decorator="[
            'sectionId',
            {rules: [{ required: true, message: '请选择二级科室' }]}
          ]"
          placeholder="二级科室"
          name="sectionId"
        >
          <a-select-option v-for="item in depChilddata" :key="item.sectionId" :value="item.sectionId">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item
        label="排序"
      >
        <a-input
          v-decorator="[
            'listorder',
            {rules: [{ required: true, message: '请输入排序' }]}
          ]"
          name="path"
          placeholder="请输入排序"/>
      </a-form-item>
      <a-form-item
        :required="false"
        label="状态"
      >
        <a-radio-group v-decorator="['status', { initialValue: 1 }]" name="status">
          <a-radio :value="1">启用</a-radio>
          <a-radio :value="2">禁用</a-radio>
        </a-radio-group>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import { allList } from '@/api/department'
import { allList as allListChild } from '@/api/departmentsectionId'
  export default {
    data () {
      return {
        labelCol: { span: 4 },
        wrapperCol: { span: 14 },
        form: this.$form.createForm(this),
        depdata: [],
        depChilddata: []
      }
    },
    created () {
      this.getAllList({})
    },
    methods: {
      getAllList (params) {
        allList(params).then(res => {
          this.depdata = res.result
          this.$forceUpdate()
        })
      },
      getAllListChild (params) {
        allListChild(params).then(res => {
          this.depChilddata = res.result
          this.$forceUpdate()
        })
      },
      onChange (value, option) {
        this.form.setFieldsValue({
          sectionId: undefined
        })
        this.getAllListChild({ depId: value })
      }

    }
  }
</script>
