<template>
  <a-form-model
    ref="ruleForm"
    :model="form"
    :rules="rules"
    :label-col="labelCol"
    :wrapper-col="wrapperCol">
    <a-form-model-item required label="排序" prop="listorder">
      <a-input-number style="width:100%" v-model="form.listorder"/>
    </a-form-model-item>
    <a-form-model-item required label="医院名称" prop="name">
      <a-input v-model="form.name"/>
    </a-form-model-item>
    <a-form-model-item required label="医院级别" prop="level">
      <a-select v-model="form.level" placeholder="请选择">
        <a-select-option :value="10">三甲</a-select-option>
        <a-select-option :value="1">一级甲等</a-select-option>
        <a-select-option :value="2">一级乙等</a-select-option>
        <a-select-option :value="3">一级丙等</a-select-option>
        <a-select-option :value="4">二级甲等</a-select-option>
        <a-select-option :value="5">二级乙等</a-select-option>
        <a-select-option :value="6">二级丙等</a-select-option>
        <a-select-option :value="7">三级甲等</a-select-option>
        <a-select-option :value="8">三级乙等</a-select-option>
        <a-select-option :value="9">三级丙等</a-select-option>
      </a-select>
    </a-form-model-item>

    <a-form-model-item label="标签" prop="tagsIds">
      <a-select
        v-model="form.tagsIds"
        name="tipsIds"
        allow-clear
        placeholder="请选择"
        mode="multiple"
      >
        <a-select-option v-for="item in tagsData" :key="item.tagsId" :value="item.tagsId">
          {{ item.name }}
        </a-select-option>
      </a-select>
    </a-form-model-item>

    <a-form-model-item required label="地区" prop="regionIds">
      <a-cascader
        v-model="form.regionIds"
        name="regionIds"
        :field-names="RegreplaceFields"
        :options="RegtreeData"
        allow-clear
        placeholder="请选择"
        @change="handleRegionChange"
      />
    </a-form-model-item>

    <a-form-model-item
      v-for="(address, index) in form.addresses"
      :key="address.key"
      :label="'医院地址'+(index+1) "
    >
      <a-input
        v-model="address.value"
        placeholder="请输入医院地址"
        style="width: 60%; margin-right: 8px"
      />
      <a-icon
        v-if="form.addresses.length > 1"
        :disabled="form.addresses.length === 1"
        class="dynamic-delete-button"
        type="minus-circle-o"
        @click="removeDomain(address)"
      />
      <a-button
        v-if="index==0 && form.addresses.length <3"
        type="primary"
        style=" margin-left: 8px"
        @click="addDomain(null,null)">
        <a-icon type="plus"/>
        新增
      </a-button>
    </a-form-model-item>

    <a-form-model-item label="Tips" prop="tips">
      <a-input v-model="form.tips" type="textarea"/>
    </a-form-model-item>
    <a-form-model-item label="备注" prop="tips">
      <a-input v-model="form.remarks" type="textarea"/>
    </a-form-model-item>
    <a-form-model-item required label="状态" prop="status">
      <a-radio-group v-model="form.status">
        <a-radio :value="1">
          启用
        </a-radio>
        <a-radio :value="2">
          禁用
        </a-radio>
      </a-radio-group>
    </a-form-model-item>
  </a-form-model>
</template>
<script>
import { cityList as RegAllList } from '@/api/region'
import { allList as TagsAllList } from '@/api/tags'

import { Cascader as ACascader } from 'ant-design-vue'

export default {
  components: {
    ACascader
  },
  data () {
    return {
      formItemLayout: {
        labelCol: {
          xs: { span: 24, offset: 4 },
          sm: { span: 4, offset: 4 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 20 }
        }
      },
      labelCol: {
        xs: { span: 24 },
        sm: { span: 4 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 20 }
      },
      formItemLayoutWithOutLabel: {
        wrapperCol: {
          xs: { span: 24, offset: 0 },
          sm: { span: 20, offset: 4 }
        }
      },
      tagsData: [],
      RegtreeData: [],
      RegreplaceFields: { label: 'name', value: 'regionId', children: 'children' },
      form: {
        listorder: undefined,
        id: undefined,
        name: '',
        regionIds: undefined,
        level: undefined,
        status: 1,
        tips: undefined,
        addresses: []
      },
      rules: {
        name: [
          { required: true, message: '请填写医院名称', trigger: 'blur' }
        ],
        regionIds: [{ required: true, message: '请选择地区', trigger: 'change' }],
        status: [{ required: true, message: '请选择状态', trigger: 'change' }]
      }
    }
  },
  mounted () {
    this.getRegAllList()
    this.getTagsAllList()
  },
  methods: {
    getTagsAllList () {
      TagsAllList().then(res => {
        this.tagsData = res.result
      })
    },
    getRegAllList () {
      RegAllList().then(res => {
        this.RegtreeData = res.result
      })
    },
    onSubmit () {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.$emit('submitForm', this.form)
          return true
        } else {
          return false
        }
      })
    },
    removeDomain (item) {
      const index = this.form.addresses.indexOf(item)
      if (index !== -1) {
        this.form.addresses.splice(index, 1)
      }
    },
    addDomain (key, value) {
      if (key == null) {
        key = parseInt(new Date().getTime() / 1000)
      }
      this.form.addresses.push({
        value: value,
        key: key
      })
    },
    reset () {
      this.form = {
        listorder: undefined,
        id: undefined,
        name: '',
        regionIds: [],
        regionName: undefined,
        level: undefined,
        status: 1,
        tips: undefined,
        addresses: [],
        remarks: undefined
      }
      this.addDomain(null, null)
    },
    editReset (data) {
      this.form = {
        listorder: data.info.listorder,
        id: data.info.id,
        name: data.info.name,
        regionIds: JSON.parse(data.info.regionIds),
        level: data.info.level,
        status: data.info.status,
        tips: data.info.tips,
        regionName: data.info.regionName,
        addresses: [],
        tagsIds: [],
        remarks: data.info.remarks
      }
      if (data.tagsList != null) {
        const arr = []
        if (data.tagsList.length > 0) {
          for (let i = 0; i < data.tagsList.length; i++) {
            arr.push(data.tagsList[i].tagsId)
          }
        }
        this.form.tagsIds = arr
      }
      if (data.address != null) {
        if (data.address.length > 0) {
          for (let i = 0; i < data.address.length; i++) {
            this.addDomain(data.address[i].addressId, data.address[i].address)
          }
        } else {
          this.addDomain(null, null)
        }
      } else {
        this.addDomain(null, null)
      }
    },
    handleRegionChange (value, nameValue) {
      const arr = []
      for (let i = 0; i < nameValue.length; i++) {
        arr.push(nameValue[i].name)
      }
      this.form.regionName = arr.join(',')
    }
  }
}
</script>
